export class Issue {

    id: string;
    title: string;
    description: string;
    statusText: string;
    statusId: number;
    typeId: number;
    typeText: string
    dateText: string
    attachedFiles: Document[];

    constructor(id: string, title: string, description: string, statusText: string, statusId: number, typeId: number, typeText: string, dateText: string, attachedFiles: Document[]) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.statusId = statusId;
        this.statusText = statusText;
        this.typeId = typeId;
        this.typeText = typeText;
        this.dateText = dateText;
        this.attachedFiles = attachedFiles;
    }

    className() {

        let className = 'issues-card';

        if (this.statusId == 100) {
            className += ' issue-pending';
        }

        if (this.statusId == 200) {
            className += ' issue-in-course';
        }

        if (this.statusId == 300) {
            className += ' issue-finished';
        }

        return className;
    }
}

export class NewIssue {

    titulo: string;
    descripcion: string;
    prioridad: string;
    contrato: string;
    tipo: number;
    fecha: any;
    fechaVencimiento: any;
    adjuntos: any[]
    
    constructor(titulo: string, descripcion: string, prioridad: string, contrato: string, tipo: number, fecha: any, fechaVencimiento: any, adjuntos: any[]) {
        this.titulo = titulo;
        this.descripcion = descripcion;
        this.prioridad = prioridad;
        this.tipo = tipo;
        this.fecha = fecha;
        this.fechaVencimiento = fechaVencimiento;
        this.adjuntos = adjuntos;
    }
}
