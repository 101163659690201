
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "FooterIcons",
  data() {
    return {
      options: [
        {
          icon: "fa-house",
          pathName: "bill",
          title: "Inicio",
        },
        {
          icon: "fa-door-open",
          pathName: "lease",
          title: "Arriendo",
        },
        {
          icon: "fa-users-line",
          pathName: "community",
          title: "Comunidad",
        },
        {
          icon: "fa-check",
          pathName: "issues",
          title: "Solicitudes",
        },
      ],
      showFooterIcons: false as boolean,
      selectedOption: "",
    };
  },
  created() {
    this.showFooterIcons =
      this.$router.currentRoute.fullPath !== "/select-lease";
    const url = this.$router.currentRoute.fullPath.replaceAll("/", "");
    this.selectedOption = !url || url === "select-lease" ? "bill" : url;
  },
  computed: {
    ...mapGetters({
      state: "getState",
    }),
  },
  methods: {
    handleClick(pathName: string) {
      if (pathName && this.$route.name !== pathName) {
        this.selectedOption = pathName;
        this.$router.push({ name: pathName });
      }
    },
  },
  watch: {
    $route(to) {
      const url = to.fullPath.replaceAll("/", "");

      if (to.fullPath !== "/select-lease") {
        this.showFooterIcons = true;
      } else {
        this.showFooterIcons = false;
      }
      if (url && !this.options.some((e) => e.pathName === url)) {
        this.selectedOption = "";
      } else {
        this.selectedOption = url || "bill";
      }
    },
  },
});
