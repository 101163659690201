import Vue from "vue";
import Vuex from "vuex";
import { State } from "@/types/state";
import { Lease } from "@/types/lease";
import { Bill } from "@/types/bill";
import { Parameters } from "@/types/parameter";
import { Unit } from "@/types/unit";
import { Annex } from "@/types/annex";
import { Document, DocumentType } from "@/types/document";
import { User } from "@/types/user";
import { Pet } from "@/types/pet";
import { Vehicle } from "@/types/vehicle";
import { Publication } from "@/types/publication";
import { CustodyDocument } from "@/types/custodyDocument";
import { Issue } from "@/types/issue";

Vue.use(Vuex);

const getInitialState = (): State => ({
  annexes: {},
  bills: {},
  billsRecord: {},
  custodyDocuments: {},
  documents: {},
  leases: [],
  issues: [],
  loading: {
    leases: true,
    issues: true,
    user: true,
  },
  parameters: {},
  pets: {},
  publications: {},
  residents: {},
  selectedLease: localStorage.getItem("lease_id") || "",
  units: {},
  user: {
    birthday: "",
    id: "",
    lastName: "",
    firstName: "",
    dni: "",
    nationality: "",
    gender: "",
    phone: "",
    email: "",
    occupation: "",
    isSmoker: false,
    identifierType: "",
  },
  vehicles: {},
});

const store = new Vuex.Store<State>({
  state: getInitialState(),
  mutations: {
    setUserInformation(
      state: State,
      {
        birthday,
        dni,
        email,
        firstName,
        gender,
        id,
        isSmoker,
        lastName,
        nationality,
        occupation,
        phone,
        identifierType,
      }: User
    ) {
      state.user.birthday = birthday;
      state.user.dni = dni;
      state.user.email = email;
      state.user.gender = gender;
      state.user.id = id;
      state.user.isSmoker = isSmoker;
      state.user.lastName = lastName;
      state.user.nationality = nationality;
      state.user.occupation = occupation;
      state.user.phone = phone;
      state.user.firstName = firstName;
      state.user.identifierType = identifierType;
      state.loading.user = false;
    },
    setLeases(state: State, leases: Lease[]) {
      state.leases = leases;
      state.loading.leases = false;
      leases.forEach((e: Lease) => {
        state.documents[e.id] = {
          annexes: null,
          checkIn: null,
          checkOut: null,
          evaluations: null,
          images: null,
          leases: null,
          rates: null,
        };
      });
    },
    setIssues(state: State, issues: Issue[]) {
      state.issues = issues;
      state.loading.issues = false;
    },
    setInitialState() {
      store.replaceState(getInitialState());
    },
    setBills(state: State, { id, bills }: { id: string; bills: Bill[] }) {
      state.bills[id] = bills;
    },
    setBillsRecord(
      state: State,
      {
        id,
        billsRecord,
      }: { id: string; billsRecord: { [key: number]: Bill[] } }
    ) {
      state.billsRecord[id] = billsRecord;
    },
    setSelectedLease(state: State, id: string) {
      state.selectedLease = id;
    },
    setImageSrcInLease(
      state: State,
      { leaseId, imagenSrc }: { leaseId: string; imagenSrc: string }
    ) {
      const index = state.leases.findIndex((e: Lease) => e.id === leaseId);
      state.leases[index].imageSrc = imagenSrc;
    },
    setDocuments(
      state: State,
      {
        documents,
        key,
        leaseId,
      }: { documents: Document[]; key: DocumentType; leaseId: string }
    ) {
      state.documents[leaseId][key] = documents;
    },
    setParameters(
      state: State,
      { id, parameters }: { id: string; parameters: Parameters }
    ) {
      state.parameters[id] = parameters;
    },
    setUnits(state: State, { id, units }: { id: string; units: Unit[] }) {
      state.units[id] = units;
    },
    setAnnexes(
      state: State,
      { id, annexes }: { id: string; annexes: Annex[] }
    ) {
      state.annexes[id] = annexes;
    },
    setCustodyDocuments(
      state: State,
      {
        id,
        custodyDocuments,
      }: { id: string; custodyDocuments: CustodyDocument[] }
    ) {
      state.custodyDocuments[id] = custodyDocuments;
    },
    setResidents(
      state: State,
      { id, residents }: { id: string; residents: User[] }
    ) {
      state.residents[id] = residents;
    },
    setPets(state: State, { id, pets }: { id: string; pets: Pet[] }) {
      state.pets[id] = pets;
    },
    setVehicles(
      state: State,
      { id, vehicles }: { id: string; vehicles: Vehicle[] }
    ) {
      state.vehicles[id] = vehicles;
    },
    setPublications(
      state: State,
      { id, publications }: { id: string; publications: Publication[] }
    ) {
      state.publications[id] = publications;
    },
    setImageSrcPublication(
      state: State,
      {
        propertyId,
        publicationId,
        imagenSrc,
      }: { propertyId: string; publicationId: string; imagenSrc: string }
    ) {
      const index = state.publications[propertyId].findIndex(
        (e: Publication) => e.id === publicationId
      );
      state.publications[propertyId][index].imageSrc = imagenSrc;
    },
  },
  getters: {
    getState(state) {
      return state;
    },
  },
});

export default store;
