/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";
import { saveAs } from "file-saver";

export const getFormattedValue = (value: string): string => {
  if (!value) {
    return "";
  }

  return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

const jsonDate = (date: any) => {
  const dateMoment = moment(date);
  return {
    year: dateMoment.format("YYYY"),
    month: dateMoment.format("MM"),
    day: dateMoment.format("DD"),
  };
};

export interface DateWithTimeToApi {
  date: { year: string; month: string; day: string };
  time: { hour: string; minute: string };
}

export const getDateWithTimeToApi = (date: Date): DateWithTimeToApi => {
  const dateMoment = moment(date);

  return {
    date: {
      year: dateMoment.format("YYYY"),
      month: dateMoment.format("MM"),
      day: dateMoment.format("DD"),
    },
    time: {
      hour: dateMoment.format("HH"),
      minute: dateMoment.format("mm"),
    },
  };
};

export const generateFilter = (filterKey: string, filter: any): string => {
  let newFilter = "";
  Object.entries(filter).forEach(([key, value]) => {
    if (value === "" || value === undefined || value === null) {
      return;
    }
    if (typeof value === "string" || typeof value === "number") {
      newFilter += "&" + filterKey + "[" + key + "]=" + value;
    } else if (
      Object.prototype.toString.call(value) === "[object Date]" ||
      value instanceof moment ||
      moment.isDate(value)
    ) {
      const date = jsonDate(value);
      newFilter += "&" + filterKey + "[" + key + "][year]=" + date.year;
      newFilter += "&" + filterKey + "[" + key + "][month]=" + date.month;
      newFilter += "&" + filterKey + "[" + key + "][day]=" + date.day;
    } else if (typeof value === "object") {
      filter[key].forEach((item: any) => {
        newFilter += "&" + filterKey + "[" + key + "][]=" + item;
      });
    }
  });
  return newFilter;
};

export const downloadFile = (file: string, name: string) => {
  saveAs(file, name);
};

export const getAvailableDays = (availableDays: number[]) => {
  let text = "Disponible los días ";

  availableDays.forEach((e, i) => {
    text += moment().day(e).format("dddd");
    if (availableDays.length > 1 && i !== availableDays.length - 1) {
      text += ", ";
    }
  });

  return text;
};

export const  readFile = (file: File): Promise<string | ArrayBuffer> => {
  return new Promise<string | ArrayBuffer>((resolve, reject) => {
    if (file.size > 100000000) {
      return reject('Ups, archivo muy grande...');
    }
    const reader = new FileReader();
    reader.onload = e => {
      return resolve((e.target as FileReader).result);
    };
    reader.onerror = e => { 
      return reject('Error al subir archivo ...');
    };
    if (!file) {
      return reject('No hay archivos ...');
    }
    reader.readAsDataURL(file);
  });
}