

import { Issue, NewIssue } from "@/types/issue";
import Vue from "vue";
import Header from "@/components/Header.vue";
import MainTabs from "@/components/MainTabs.vue";
import { mapGetters } from "vuex";
import { createIssue, downloadTareaImagen, getIssues } from "@/actions/issue";
import { Lease } from "@/types/lease";
import moment from "moment";
import { readFile } from "@/utils";
import Trigger from "@/utils/trigger.vue";
import { saveAs } from "file-saver";


const ESTADO_PENDIENTE = 100;
const ESTADO_EN_CURSO = 200;
const ESTADO_FINALIZADAS = 300;


export default Vue.extend({
  name: "IssueList",
  components: { MainTabs, Header, Trigger },
  created() {
    this.loadIssues();
  },
  computed: {
    ...mapGetters({
      state: "getState",
    }),
  },
  watch: {
    "state.leases"() {
      this.loadIssues();
    },
    "state.selectedLease"() {
      this.loadIssues();
    },
  },
  methods: {
    //
    //
    previewFiles(event) {
      const files = event.target.files;
      this.files = files;
    },
    //
    //
    async createAdjuntoFormat(file: any) {

      const result = await readFile(file).then(fileContents => {
        const splitB64 = fileContents.toString().split(',');
        return { id: null, fileAdjunto: splitB64[1], nombre: file.name, adjuntoTipoId: 1 };
      });

      return result;
    },
    //
    //
    getPropertyId() {

      const selectedLease = this.state.selectedLease;
      return (
        this.state.leases.find((e: Lease) => e.id === selectedLease)
          ?.propertyId || ""
      );
    },
    //
    //
    loadMoreItems() {

      if (this.pageTotal <= this.page) {
        return;
      }

      this.loadingMoreItems = true;
      this.page = this.page + 1;
      this.loadIssues(this.selectedTab);

    },
    //
    //
    filterIssues(issues: Issue[], selectedTab) {
      return issues.filter((issue) => issue.statusId == selectedTab);
    },
    //
    //
    loadIssues(estado: number = ESTADO_PENDIENTE) {

      const propertyId: string = this.getPropertyId();
      this.isLoading = true;

      if (propertyId) {

        getIssues(this.state.selectedLease, estado, this.page)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .then(({ pager, issues }: any) => {

            this.issues = [...this.issues, ...issues];
            this.isLoading = false;
            this.showTabs = true;
            this.pageTotal = pager.page_total;
            this.loadingMoreItems = false;

            this.tabs = [
              { id: ESTADO_PENDIENTE, title: 'Pendientes' },
              { id: ESTADO_EN_CURSO, title: 'En curso' },
              { id: ESTADO_FINALIZADAS, title: 'Cerradas' },
            ];

          })
          .finally(() => (this.isLoading = false, this.showTabs = true));
      }
    },
    //
    //
    selectIssue(issue: Issue) {
      this.files = [];
      this.selectedIssue = issue;
    },
    //
    //
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.handleSubmit()
    },
    //
    //
    async downloadImage(id: string, name: string) {
      const tareaId = this.selectedIssue.id;
      const binary = await downloadTareaImagen(id, tareaId);
      saveAs(binary as string, name);
    },
    //
    //
    resetModal() {

      this.showErrorForm = false;
      this.formState.title = true;
      this.formState.description = true;
      this.formState.issueType = true;

      this.form.title = '';
      this.form.description = '';
      this.form.issueType = null;
      this.form.files = [];
      this.files = [];

    },
    //
    //
    checkFormValidity() {

      const valid = this.$refs.form.checkValidity();

      this.formState.issueType = this.form.issueType != null ? true : false;
      this.formState.title = this.form.title != '' ? true : false;
      this.formState.description = this.form.description != '' ? true : false;
      this.formState.files = this.form.files.length > 0 ? true : false;

      return valid;
    },
    //
    //
    async handleSubmit() {

      if (!this.checkFormValidity()) {
        return;
      }

      this.loadingSubmitForm = true;
      this.showErrorForm = false;

      const adjuntos = [];

      for (let i = 0; i < this.form.files.length; i++) {
        adjuntos.push(await this.createAdjuntoFormat(this.form.files[i]));
      }

      const dateMoment = moment();
      const fechaVencimiento = moment().add(15, 'days');

      const newIssue: NewIssue = {
        titulo: this.form.title,
        descripcion: this.form.description,
        tipo: this.form.issueType,
        prioridad: 'alta',
        contrato: this.state.selectedLease,
        adjuntos: adjuntos,
        fecha: {
          date: {
            year: +dateMoment.format('YYYY'),
            month: +dateMoment.format('MM'),
            day: +dateMoment.format('DD')
          },
          time: {
            hour: +dateMoment.format('HH'),
            minute: +dateMoment.format('mm'),
          }
        },
        fechaVencimiento: {
          date: {
            year: +fechaVencimiento.format('YYYY'),
            month: +fechaVencimiento.format('MM'),
            day: +fechaVencimiento.format('DD')
          },
          time: {
            hour: +dateMoment.format('HH'),
            minute: +dateMoment.format('mm'),
          }
        }
      };

      createIssue(newIssue)
        .then(({ error }) => {

          if (error === true) {
            this.showErrorForm = true;
            this.loadingSubmitForm = false;
            return;
          }

          Vue.swal('Se ha enviado la solicitud a Pop Estate', '', 'success');

          this.page = 1;
          this.issues = [];
          this.closeCreateModal();
          this.loadIssues();
          this.loadingSubmitForm = false;
        });

    },
    //
    //
    onSelectTab(id: number) {
      this.issues = [];
      this.page = 1;
      this.selectedTab = id;
      this.loadIssues(id);
    },
    //
    //
    closeCreateModal() {
      this.$refs['create-issue'].hide()
    },
  },
  data() {
    return {
      slide: 0,
      sliding: null,
      pageTotal: 0,
      page: 1,
      pager: null,
      tabs: [],
      loadingMoreItems: false,
      loadingImages: true,
      loadingSubmitForm: false,
      showErrorForm: false,
      selectedIssue: null,
      selectedTab: ESTADO_PENDIENTE,
      showTabs: false,
      isLoading: true,
      collapses: [],
      issues: [],
      filteredIssues: [],
      files: [],
      formState: {
        title: null,
        description: null,
        files: null,
        issueType: null,
      },
      form: {
        title: '',
        description: '',
        files: [],
        issueType: null,
      },
      issueTypes: [
        { text: 'Seleccionar', value: null }, 
        { text: 'Mantención', value: 1 }, 
        { text: 'Reparación', value: 2 }, 
        { text: 'Limpieza', value: 3 }, 
        { text: 'Servicios', value: 4 }, 
        { text: 'Otro', value: 12 }],
    };
  },
});
