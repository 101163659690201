/* eslint-disable @typescript-eslint/no-explicit-any */
import api, { getHeaders } from "@/utils/api";
import { Document } from "@/types/document";
import { Issue, NewIssue } from "@/types/issue";
import { generateFilter } from "@/utils";


export const getIssues = (contrato: string, estado: number, page = 1) =>
  new Promise((resolve, reject) => {

    let filterToApply = "";

    if (page) {
      filterToApply += "&page=" + page;
    }

    filterToApply += generateFilter("tarea_tenant_filter", {
      contrato: contrato,
      estado: estado
    });
    
    api
      .get(`/tenant/tareas?${filterToApply}`, getHeaders())
      .then((res) => {

        let issues: Issue[] = [];

        res.data.data.tareas.forEach(
          ({
            id,
            titulo,
            descripcion,
            estado_text,
            estado_id,
            tipo_text,
            tipo_id,
            adjuntos,
            fecha
          }: any) => {

            const attachedFiles: Document[] = [];

            if (adjuntos && adjuntos.length) {

              adjuntos.forEach((e: any) => {

                const splitted = e.nombre_archivo.split(".");

                attachedFiles.push({
                  entityId: "",
                  id: e.id,
                  name: e.descripcion,
                  type: splitted[splitted.length - 1],
                  weight: e.peso,
                });
              });
            }

            const issue = new Issue(id, titulo, descripcion, estado_text, estado_id, tipo_id, tipo_text, fecha, adjuntos);

            issues.push(issue);
            
          }
        );

        const pageTotal = res.data.data.pager.page_total;
        if(page > pageTotal) {
          issues = [];
        }

        resolve({ issues, pager: res.data.data.pager });
      })
      .catch((err) => {
        reject(err);
      });
  });

export const downloadTareaImagen = (
  imageId: string,
  tareaId: string
) =>
  new Promise((resolve, reject) => {

    api
      .get(
        `/tenant/tareas/${tareaId}/adjuntos/${imageId}/download`,
        getHeaders()
      )
      .then((res: any) => {
        resolve(res.data.data.file_data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const createIssue = (
  newIssue: NewIssue
): Promise<{ error: boolean, data: any }> =>
  new Promise((resolve, reject) => {
    api
      .post(`/tenant/tareas/crear`, newIssue, getHeaders())
      .then((res) => {
        resolve({
          error: false,
          data: null
        });
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          resolve({
            error: true,
            data: error.response.data.data
          });
        } else {
          reject(error);
        }
      });
  });

export function recorrerErrores(valores: any): string {
  let result = "";
  valores.forEach((item: any) => {
    result += "[* " + item.detail + "\n]";
  });
  return result;
}